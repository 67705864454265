import React from 'react';
import Layout from '../components/Layout/layout';
import Terms from '../components/Terms/Terms';

const PrivacyPage: React.FC = () => {
  return (
    <Layout>
      <Terms />
    </Layout>
  );
};

export default PrivacyPage;
